import { MetaContentHead } from '@/components/common/MetaContentHead'
import { Layout } from '@/components/Layout'
import { ButtonLink } from '@/components/ui/ButtonLinks'
import { fetchMenuCategories } from '@/utils/Contentful'
import { GetStaticProps } from 'next'

const NotFound = ({ menuLinks }): JSX.Element => {
  return (
    <Layout menuLinks={menuLinks}>
      <MetaContentHead metaTitle="Fora - 404 Not Found" metaDescription="" />

      <section>
        <div className="p-8 m-auto text-center lg:w-2/3 lg:pb-14 lg:p-18">
          <h1 className="fora-text-h2">404 Page Not Found</h1>
        </div>
      </section>

      <section className="mb-12 text-center lg:mb-32 sm:mt-0">
        <p className="mb-8 fora-text-body-1 lg:mb-14 text-darkStone">
          We can’t seem to find the page you are looking for.
        </p>
        <ButtonLink
          href="/"
          target="_self"
          text="Back to Homepage"
          theme="primary"
        />
      </section>
    </Layout>
  )
}
export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      menuLinks: await fetchMenuCategories(),
    },
  }
}
export default NotFound
